var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-user-details-modal","title":_vm.$t('users.editing_user_details'),"centered":"","ok-title":_vm.$t('general.save'),"cancel-title":_vm.$t('general.cancel'),"no-close-on-backdrop":"","size":"lg"},on:{"ok":_vm.saveClick}},[_c('validation-observer',{ref:"editUserDetailsValidations"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.last_name'),"label-for":"last-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.last_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name"},model:{value:(_vm.user_data.contact.last_name),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "last_name", $$v)},expression:"user_data.contact.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.first_name'),"label-for":"first-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.first_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name"},model:{value:(_vm.user_data.contact.first_name),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "first_name", $$v)},expression:"user_data.contact.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.middle_name'),"label-for":"middle-name"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.middle_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middle-name"},model:{value:(_vm.user_data.contact.middle_name),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "middle_name", $$v)},expression:"user_data.contact.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.gender')+':',"label-for":"gender"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.gender')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"gender","options":_vm.$data.$_gender_options,"text-field":("title." + (_vm.$i18n.locale)),"value-field":"key","name":"radio-inline"},model:{value:(_vm.user_data.contact.gender),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "gender", $$v)},expression:"user_data.contact.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.phone_number')+':',"label-for":"phone_number"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.phone_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('masked-input',{model:{value:(_vm.user_data.contact.phone),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "phone", $$v)},expression:"user_data.contact.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"email","name":_vm.$t('users.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user_data.contact.email),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "email", $$v)},expression:"user_data.contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.roles'),"label-for":"roles"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.middle_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"roles","multiple":"","options":_vm.GET_USER_ROLES_LIST.results,"get-option-label":function (el) { return el.title[_vm.$i18n.locale]; },"reduce":function (opt) { return opt.id; }},model:{value:(_vm.user_data.roles),callback:function ($$v) {_vm.$set(_vm.user_data, "roles", $$v)},expression:"user_data.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.level'),"label-for":"roles"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('users.level')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"level","options":_vm.GET_LEVELS_LIST,"label":"level","reduce":function (opt) { return opt.id; }},model:{value:(_vm.user_data.contact.level),callback:function ($$v) {_vm.$set(_vm.user_data.contact, "level", $$v)},expression:"user_data.contact.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }