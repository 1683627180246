var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"id":"hub-reg-table","fields":_vm.fields,"items":_vm.courses,"hover":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(subject)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(item.subject.title[_vm.$i18n.locale])+" ")])]}},{key:"cell(access)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$_dateTimeFormatter(item.access_start_date).split(',')[0])+" - "+_vm._s(_vm.$_dateTimeFormatter(item.access_end_date).split(',')[0])+" ")]}},{key:"cell(teachers)",fn:function(ref){
var item = ref.item;
return _vm._l((item.teachers),function(teacher){return _c('b-badge',{key:teacher.id,attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(teacher.full_name)+" ")])})}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-around"},[_c('b-button',{staticClass:"p-50",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$emit('editBtnClicked', item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"p-50",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteBtnClicked(item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }