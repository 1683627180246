<template>
  <b-modal
    id="edit-user-details-modal"
    :title="$t('users.editing_user_details')"
    centered
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    size="lg"
    @ok="saveClick"
  >
    <validation-observer ref="editUserDetailsValidations">
      <b-row>
        <b-col md="4">
          <b-form-group
            :label="$t('users.last_name')"
            label-for="last-name"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.last_name')"
            >
              <b-form-input
                id="last-name"
                v-model="user_data.contact.last_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.first_name')"
            label-for="first-name"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.first_name')"
            >
              <b-form-input
                id="first-name"
                v-model="user_data.contact.first_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.middle_name')"
            label-for="middle-name"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.middle_name')"
            >
              <b-form-input
                id="middle-name"
                v-model="user_data.contact.middle_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('users.gender')+':'"
            label-for="gender"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.gender')"
            >
              <b-form-radio-group
                id="gender"
                v-model="user_data.contact.gender"
                :options="$data.$_gender_options"
                :text-field="`title.${$i18n.locale}`"
                value-field="key"
                name="radio-inline"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.phone_number')+':'"
            label-for="phone_number"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.phone_number')"
            >
              <masked-input v-model="user_data.contact.phone" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              rules="email"
              :name="$t('users.email')"
            >
              <b-form-input v-model="user_data.contact.email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.roles')"
            label-for="roles"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.middle_name')"
            >
              <v-select
                id="roles"
                v-model="user_data.roles"
                multiple
                :options="GET_USER_ROLES_LIST.results"
                :get-option-label="el => el.title[$i18n.locale]"
                :reduce="opt => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            :label="$t('users.level')"
            label-for="roles"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('users.level')"
            >
              <v-select
                id="level"
                v-model="user_data.contact.level"
                :options="GET_LEVELS_LIST"
                label="level"
                :reduce="opt => opt.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BFormRadioGroup,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import maskedInput from '@/components/maskedInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    maskedInput,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      user_data: {},
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USER_ROLES_LIST', 'GET_LEVELS_LIST']),
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
  },
  watch: {
    userData() {
      this.user_data = this.userData
    },
  },
  mounted() {
    this.FETCH_USER_ROLES_LIST()
    this.FETCH_SUBJECTS_LIST({ page_size: 100 })
  },
  methods: {
    ...mapActions('users', ['FETCH_USER_ROLES_LIST', 'UPDATE_USER']),
    ...mapActions('contacts', ['EDIT_ONE_CONTACT']),
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST', 'ATTACH_SUBJECTS_TO_TEACHER']),
    async saveClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      const success = await this.$refs.editUserDetailsValidations.validate()
      if (!success) return
      const { contact, subjects, ...rest } = this.user_data
      Promise.all([
        this.UPDATE_USER({ ...rest }),
        this.EDIT_ONE_CONTACT(contact),
        // this.ATTACH_SUBJECTS_TO_TEACHER({ subject: subjects, teacher: contact.id }),
      ]).then(() => {
        this.$_successToast(this.$t('general.success'))
        this.$emit('saveSuccess')
      })
    },
  },
}
</script>

<style>

</style>
