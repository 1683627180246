<template>
  <b-table
    id="hub-reg-table"
    :fields="fields"
    :items="courses"
    hover
  >
    <template #head()="{ label }">
      {{ $t(label) }}
    </template>
    <template #cell(subject)="{ item }">
      <b-badge variant="info">
        {{ item.subject.title[$i18n.locale] }}
      </b-badge>
    </template>
    <template #cell(access)="{ item }">
      {{ $_dateTimeFormatter(item.access_start_date).split(',')[0] }} - {{ $_dateTimeFormatter(item.access_end_date).split(',')[0] }}
    </template>
    <template #cell(teachers)="{ item }">
      <b-badge
        v-for="teacher in item.teachers"
        :key="teacher.id"
        variant="info"
      >
        {{ teacher.full_name }}
      </b-badge>
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-around">
        <b-button
          class="p-50"
          variant="warning"
          @click="$emit('editBtnClicked', item)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          class="p-50"
          variant="danger"
          @click="deleteBtnClicked(item.id)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BButton, BBadge } from 'bootstrap-vue'
import extra from '@/mixins/extra'

export default {
  components: {
    BTable,
    BButton,
    BBadge,
  },
  mixins: [extra],
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'course.title',
          label: 'users.course_title',
        },
        {
          key: 'course.price',
          label: 'users.price',
        },
        // {
        //   key: 'subject',
        //   label: 'users.subject',
        // },
        {
          key: 'teachers',
          label: 'users.teachers',
        },
        {
          key: 'access',
          label: 'users.access',
        },
        {
          key: 'actions',
          label: 'general.actions',
          tdClass: 'width-100',
        },
      ],
    }
  },
  methods: {
    deleteBtnClicked(id) {
      this.$_showAreYouSureModal().then(res => {
        if (res) {
          this.$emit('deleteBtnClicked', id)
        }
      })
    },
  },
}
</script>

<style>

</style>
