<template>
  <div>
    <div
      v-if="isStudent"
      class="d-flex justify-content-between align-items-end mb-1"
    >
      <div>
        <label for="search">{{ $t("general.search") }}</label>
        <b-form-input
          v-model="query_params.search"
          @input="searchEnrolledCourses"
        />
      </div>
      <div>
        <b-button
          v-b-modal.enroll-new-course-modal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("general.add") }}
        </b-button>
      </div>
    </div>
    <hub-reg-table
      v-if="isStudent"
      :courses="GET_COURSES_BY_CONTACT"
      @deleteBtnClicked="removeCourse"
      @editBtnClicked="openEditCourseAttachmentModal"
    />
    <div v-else>
      <course-list-of-teacher :courses="GET_COURSES_BY_CONTACT" />
    </div>
    <enroll-new-course-modal
      :page-size="query_params.page_size"
      :render-increment="modal_render_increment"
      :courses="GET_COURSES_LIST.results"
      :enrolled-courses="all_enrolled_courses"
      :search-results="GET_COURSES_LIST.results"
      @search="searchCourses"
      @scrollEnd="fetchNewCourses"
      @saveClick="addNewCourses"
    />
    <edit-course-attachment-modal
      :course-item="course_attachment_to_edit"
      @saveClick="editCourseAttachment"
    />
  </div>
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import hubRegTable from '../tables/hubRegTable.vue'
import enrollNewCourseModal from '../modals/enrollNewCourseModal.vue'
import editCourseAttachmentModal from '../modals/editCourseAttachmentModal.vue'
import courseListOfTeacher from '../tables/courseListOfTeachers.vue'

export default {
  components: {
    BFormInput,
    BButton,
    hubRegTable,
    enrollNewCourseModal,
    editCourseAttachmentModal,
    courseListOfTeacher,
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
    isStudent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      query_params: {
        search: '',
        page: 1,
        page_size: 5,
      },
      items: [],
      modal_render_increment: 0,
      course_attachment_to_edit: {
        teachers: [],
        access: {},
      },
      all_enrolled_courses: [],
      delay: 300,
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_COURSES_LIST']),
    ...mapGetters('contacts', ['GET_COURSES_BY_CONTACT']),
  },
  watch: {
    contactId() {
      this.refreshListOfStudentCourses()
    },
  },
  mounted() {
    this.FETCH_COURSES_BY_CONTACT({ id: this.contactId }).then(() => {
      this.all_enrolled_courses = this.$_removeObjectConnection(this.GET_COURSES_BY_CONTACT)
      this.FETCH_COURSES_LIST(this.query_params).then(() => {
        this.modal_render_increment += 1
      })
    })
  },
  methods: {
    ...mapActions('courses', ['FETCH_COURSES_LIST', 'CREATE_CLASS_COURSE', 'DELETE_CLASS_COURSE', 'UPDATE_CLASS_COURSE']),
    ...mapActions('contacts', ['FETCH_COURSES_BY_CONTACT']),
    searchCourses(params) {
      // this.query_params.search = text
      this.FETCH_COURSES_LIST(params)
    },
    refreshListOfStudentCourses() {
      this.FETCH_COURSES_BY_CONTACT({ id: this.contactId, search: this.query_params.search })
    },
    fetchNewCourses() {
      if (this.GET_COURSES_LIST.next) {
        this.query_params.page += 1
        this.FETCH_COURSES_LIST(this.query_params).then(() => {
          this.modal_render_increment += 1
        })
      }
    },
    addNewCourses(reqBody) {
      const newReqBody = reqBody.map(el => ({ student: this.contactId, is_individual: true, ...el }))
      this.CREATE_CLASS_COURSE({ objects: newReqBody }).then(() => {
        this.$_successToast(this.$t('general.success'))
        this.$bvModal.hide('enroll-new-course-modal')
        this.refreshListOfStudentCourses()
        this.$emit('refreshUser')
      })
    },
    removeCourse(id) {
      this.DELETE_CLASS_COURSE(id).then(() => {
        this.refreshListOfStudentCourses()
      })
    },
    openEditCourseAttachmentModal(item) {
      const {
        id, access_start_date, access_end_date, teachers, course,
      } = item
      this.course_attachment_to_edit = {
        id,
        access: { start: access_start_date, end: access_end_date },
        teachers: teachers.map(el => el.id),
        course: course.id,
      }
      this.$bvModal.show('edit-course-attachment-modal')
    },
    editCourseAttachment(courseAttachment) {
      this.UPDATE_CLASS_COURSE(courseAttachment).then(() => {
        this.refreshListOfStudentCourses()
      })
    },
    searchEnrolledCourses() {
      console.log('ssss')
      clearTimeout(this.delay)
      this.delay = setTimeout(() => {
        this.refreshListOfStudentCourses()
      }, 300)
    },
  },
}
</script>

<style></style>
