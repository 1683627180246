<template>
  <div>
    <b-card no-body>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.id') }}</span>
          </div>
          <div
            class="d-flex justify-content-end text-right align-items-center w-50"
          >
            {{ info.id }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="UserIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.full_name') }}</span>
          </div>
          <div
            class="d-flex justify-content-end text-right align-items-center w-50"
          >
            {{ info.full_name }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="AwardIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.gender') }}</span>
          </div>
          <div
            v-if="$data.$_gender_options.find(el => el.key === info.gender)"
            class="d-flex justify-content-end align-items-center"
          >
            {{ $data.$_gender_options.find(el => el.key === info.gender).title[$i18n.locale] }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="AwardIcon"
                size="16"
              />
            </span>
            <span class="text-uppercase">{{ $t('users.role') }}</span>
          </div>
          <div
            v-if="info.roles.length"
            class="d-flex justify-content-end align-items-center"
          >
            <b-badge
              v-for="role in info.roles"
              :key="role.id"
              variant="primary"
              class="ml-50 "
            >
              {{ role[$i18n.locale] }}
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="PhoneIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.phone_number') }}</span>
          </div>
          <div
            class="d-flex justify-content-end align-items-center"
          >
            {{ info.phone }}
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="MailIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.email') }}</span>
          </div>
          <div
            class="d-flex justify-content-end align-items-center"
          >
            <a
              v-if="info.email"
              :href="`mailto:${info.email}`"
            > {{ info.email }} </a>
            <span v-else>
              {{ $t('general.no_info') }}
            </span>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="BarChartIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.level') }}</span>
          </div>
          <div><h4 class="mb-0">
            {{ info.level }}
            <span
              class="text-muted mb-0"
              style="font-size: 15px"
            >LVL</span>
          </h4></div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
            </span>
            <span>{{ $t('users.date_joined') }}</span>
          </div>
          <div>{{ info.date_joined }}</div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="warning"
              @click="$emit('editClick')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <!-- <div
        v-for="(val, key) in info"
        :key="key"
        class="text-white mb-50"
      >
        <feather-icon
          size="20"
          :icon="icons[key]"
        />
        {{ val }}
      </div>
      <div class="d-flex">
        <b-button
          class="p-50 ml-auto"
          variant="outline-light"
          @click="$emit('editClicked')"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div> -->
    </b-card>
    <b-card no-body>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="TagIcon"
                size="16"
              />
            </span>
            <span>{{ $t('references.tags') }}</span>
          </div>
          <div
            v-if="info.tags.length"
            class="d-flex flex-column justify-content-end align-items-center"
          >
            <b-badge
              v-for="tag in info.tags"
              :key="tag.id"
              variant="primary"
              class="ml-50 mt-50"
            >
              {{ tag.title }}
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div>
            <span class="mr-1">
              <feather-icon
                icon="BookIcon"
                size="16"
              />
            </span>
            <span>{{ $t('references.subjects') }}</span>
          </div>
          <div
            v-if="info.subjects.length"
            class="d-flex flex-column justify-content-end align-items-center"
          >
            <b-badge
              v-for="subject in info.subjects"
              :key="subject.id"
              variant="primary"
              class="ml-50 mt-50"
            >
              {{ subject.title[$i18n.locale] }}
            </b-badge>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="warning"
              @click="$emit('editSubjectsClick')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    // BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        username: 'PocketIcon',
        email: 'MailIcon',
        phone: 'PhoneIcon',
      },
    }
  },
}
</script>

<style>

</style>
