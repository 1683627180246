<template>
  <b-row>
    <b-col md="3">
      <profile-photo
        ref="profilePhoto"
        :photo-url="GET_USER.contact.photo"
        @saveClick="changePhoto"
      />
      <contact-info-card
        :info="getUserMainInfo"
        @editClick="openEditDetailsModal"
        @editSubjectsClick="openEditSubjectsModal"
      />
    </b-col>
    <b-col md="9">
      <b-card>
        <b-tabs lazy>
          <b-tab
            :title="$t('users.courses')"
          >
            <div v-if="getUserRoleUniqueNames.some(r => ['student', 'teacher'].includes(r))">
              <hub-reg-tab
                v-if="GET_USER.contact.id"
                :contact-id="GET_USER.contact.id"
                :is-student="getUserRoleUniqueNames.includes('student')"
              />
            </div>
          </b-tab>
          <b-tab :title="$t('users.actions_history')">
            <auth-logs-tab />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <edit-user-details-modal
      :user-data="user_data_to_edit"
      @saveSuccess="refresh"
    />
    <edit-user-subjects-modal
      :user-data="user_subjects_to_edit"
      @saveSuccess="refresh"
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import contactInfoCard from './components/contactInfoCard.vue'
import profilePhoto from '@/components/profilePhoto.vue'
import hubRegTab from './components/tabs/HubRegTab.vue'
import authLogsTab from './components/tabs/authLogsTab.vue'
import formatter from '@/mixins/extra'
import editUserDetailsModal from './components/modals/editUserDetailsModal.vue'
import editUserSubjectsModal from './components/modals/editUserSubjectsModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    contactInfoCard,
    hubRegTab,
    authLogsTab,
    profilePhoto,
    editUserDetailsModal,
    editUserSubjectsModal,
  },
  mixins: [formatter],
  data() {
    return {
      user_data_to_edit: {},
      user_subjects_to_edit: {},
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USER']),
    ...mapGetters('contacts', ['GET_SUBJECTS_OF_CONTACT']),
    getUserMainInfo() {
      const {
        username, roles, contact, date_joined,
      } = this.GET_USER
      return {
        id: contact.id,
        username: username || `${contact.first_name} ${contact.last_name}`,
        phone: this.$_phoneNumberFormatter(contact.phone),
        full_name: `${contact.last_name} ${contact.first_name} ${contact.middle_name}`,
        email: contact.email || this.$t('general.no_info'),
        roles: roles.map(el => el.title),
        level: contact.level,
        date_joined: this.$_dateTimeFormatter(date_joined),
        subjects: this.GET_SUBJECTS_OF_CONTACT,
        gender: contact.gender,
      }
    },
    getContactId() {
      return this.GET_USER.contact.id
    },
    getUserRoleUniqueNames() {
      return this.GET_USER.roles.map(el => el.unique_name)
    },
  },
  mounted() {
    this.FETCH_USER(this.$route.params.id).then(() => {
      this.updateBreadcrumb()
      this.FETCH_SUBJECTS_OF_CONTACT(this.GET_USER.contact.id)
    })
  },
  methods: {
    ...mapActions('users', ['FETCH_USER']),
    ...mapActions('contacts', ['EDIT_ONE_CONTACT', 'FETCH_SUBJECTS_OF_CONTACT']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    updateBreadcrumb() {
      const [first, second, third] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_USER.contact.first_name
      this.UPDATE_BREADCRUMB([first, second, third])
    },
    changePhoto(file) {
      this.EDIT_ONE_CONTACT(this.$_objectToFormData({ id: this.getContactId, photo: file })).then(() => {
        this.$_successToast(this.$t('general.change_success'))
        this.FETCH_USER(this.$route.params.id).then().then(() => {
          this.$refs.profilePhoto.cancelChange()
        })
      })
    },
    refresh() {
      this.FETCH_USER(this.$route.params.id).then(() => {
        this.FETCH_SUBJECTS_OF_CONTACT(this.GET_USER.contact.id)
      })
      this.$bvModal.hide('edit-user-details-modal')
      this.$bvModal.hide('edit-user-subjects-modal')
    },
    openEditDetailsModal() {
      const { contact, roles, ...others } = this.GET_USER
      const {
        author, branch, photo, ...rest
      } = contact
      this.user_data_to_edit = {
        roles: roles.map(el => el.id),
        role_unique_names: roles.map(el => el.unique_name),
        ...others,
        contact: {
          ...rest,
        },
      }
      this.$bvModal.show('edit-user-details-modal')
    },
    openEditSubjectsModal() {
      const { contact } = this.GET_USER
      this.user_subjects_to_edit = {
        subjects: this.GET_SUBJECTS_OF_CONTACT.map(el => el.id),
        contact: {
          id: contact.id,
        },
      }
      this.$bvModal.show('edit-user-subjects-modal')
    },
  },
}
</script>

<style>

</style>
