<template>
  <div>
    <b-card>
      <div class="text-center">
        <b-img
          v-if="photo"
          class="mb-1"
          style="height: 170px"
          thumbnail
          fluid
          :src="photo"
          alt="Фото Ученика"
        />
        <div
          v-if="!photo"
          class="mb-1"
          style="
                  border: 1px dashed #ccc;
                  padding: 74px;
                  border-radius: 5px;
                "
        >
          {{ $t('users.no_photo') }}
        </div>
      </div>
      <!--:src="user.photo"-->
      <b-form-file
        v-model="new_file"
        accept="image/*"
        :browse-text="$t('general.upload')"
        :placeholder="$t('general.select_file')"
        :drop-placeholder="$t('Виберите файл')"
        @input="fileChanged"
      />
      <div class="d-flex justify-content-around mt-1">
        <b-button @click="cancelChange">
          {{ $t('general.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="saveChange"
        >
          {{ $t('general.save') }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormFile, BImg, BButton,
} from 'bootstrap-vue'
import formatter from '@/mixins/extra'

export default {
  components: {
    BCard,
    BFormFile,
    BImg,
    BButton,
  },
  mixins: [formatter],
  props: {
    photoUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      photo: '',
      new_file: null,
    }
  },
  watch: {
    photoUrl() {
      this.photo = this.photoUrl
    },
  },
  mounted() {
    this.photo = this.photoUrl
  },
  methods: {
    fileChanged() {
      this.$_convertToBase64(this.new_file).then(res => {
        this.photo = res
      })
    },
    cancelChange() {
      this.photo = this.photoUrl
      this.new_file = null
    },
    saveChange() {
      this.$emit('saveClick', this.new_file)
    },
  },
}
</script>

<style>

</style>
