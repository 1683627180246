<template>
  <input
    v-model="content"
    v-mask="maskString"
    type="text"
    class="form-control"
    @input="updateValue"
  >
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  directives: {
    mask: VueMaskDirective,
  },
  props: {
    value: {
      type: null,
      required: true,
    },
    maskString: {
      type: String,
      required: false,
      default: '+###-##-###-##-##',
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.content.replaceAll(/[^a-zA-Z0-9]/g, ''))
    },
  },
}
</script>

<style>

</style>
