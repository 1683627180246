<template>
  <b-table
    id="course-list-of-teacher"
    striped
    :fields="fields"
    :items="courses"
  >
    <template #head()="{ label }">
      {{ $t(label) }}
    </template>
    <template #cell(students)="{ item }">
      <b-badge variant="primary">
        {{ item.student.full_name }}
      </b-badge>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'course.title',
          label: 'users.course_title',
        },
        {
          key: 'course.price',
          label: 'users.price',
        },
        // {
        //   key: 'subject',
        //   label: 'users.subject',
        // },
        {
          key: 'students',
          label: 'classes.students',
        },
      ],
    }
  },
}
</script>

<style>

</style>
