<template>
  <b-table
    id="hub-reg-table"
    :fields="fields"
    :items="logs"
    hover
  >
    <template #head()="{ label }">
      {{ $t(label) }}
    </template>
    <template #cell(login_datetime)="{ item }">
      {{ $_dateTimeFormatter(item.login_datetime) }}
    </template>
    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-around">
        <b-button
          class="p-50"
          variant="warning"
          @click="$emit('editBtnClicked', item)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          class="p-50"
          variant="danger"
          @click="deleteBtnClicked(item.id)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import formatters from '@/mixins/extra'

export default {
  components: {
    BTable,
    BButton,
  },
  mixins: [formatters],
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'login_datetime',
          label: 'users.datetime',
        },
        {
          key: 'ip',
          label: 'users.ip',
        },
        {
          key: 'action',
          label: 'general.actions',
        },
        {
          key: 'user_agent_info',
          label: 'users.browser_info',
        },
      ],
    }
  },
  methods: {
    deleteBtnClicked(id) {
      this.$_showAreYouSureModal().then(res => {
        if (res) {
          this.$emit('deleteBtnClicked', id)
        }
      })
    },
  },
}
</script>

<style>

</style>
